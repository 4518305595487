import * as React from "react"
import { Link } from "gatsby"
import * as styles from "./404.module.scss"
import Icon from "../icon/404"
import { useRef } from "react"

const LanguageCommunicate = {
  pl: {
    title: "Ohhh... Bez paniki!",
    pOne: "To tylko 404!",
    quoteTitle: '"Prawo Hofstadtera mówi:',
    quote:
      'Wszystko zajmuje więcej czasu, niż się spodziewałeś, nawet jeżeli weźmiesz pod uwagę prawo Hofstadtera."',
    home: "Wróć do strony głównej",
  },
  en: {
    title: "Ohhh... Don't panic!",
    pOne: "It's just 404!",
    quoteTitle: `"Hofstadter's law states:`,
    quote:
      'project always takes longer than expected, even when the law is taken into account."',
    home: "Go home.",
  },
}

const NotFoundPage = (props: any) => {
  const language = useRef(
    props.location.pathname.includes("/pl/") ? "pl" : "en"
  )

  return (
    <>
      <main className={styles.main}>
        <div className={styles.leftColumn}>
          <Icon className={styles.icon} />
        </div>
        <div className={styles.rightColumn}>
          <h3 className={styles.title}>
            {LanguageCommunicate[language.current as "en"].title}
          </h3>
          <p className={styles.pOne}>
            {LanguageCommunicate[language.current as "en"].pOne}
          </p>
          <p className={styles.quoteTitle}>
            {LanguageCommunicate[language.current as "en"].quoteTitle}
          </p>
          <p className={styles.quote}>
            {LanguageCommunicate[language.current as "en"].quote}
          </p>
          <Link className={styles.home} to="/">
            {LanguageCommunicate[language.current as "en"].home}
          </Link>
        </div>
      </main>
    </>
  )
}

export default NotFoundPage
