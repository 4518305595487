// extracted by mini-css-extract-plugin
export var fx = "b_c";
export var home = "b_n";
export var icon = "b_h";
export var invisible = "b_b";
export var leftColumn = "b_f";
export var main = "b_d";
export var pOne = "b_k";
export var quote = "b_m";
export var quoteTitle = "b_l";
export var rightColumn = "b_g";
export var title = "b_j";